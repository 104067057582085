import React from 'react';
import Autocomplete from "./AutoComplete";
import {Suggestion} from "../types/Suggestion";

export default function SearchBox({suggestions, handleItemSelected, placeholder}: {
    suggestions: Suggestion[],
    handleItemSelected: (suggestion: Suggestion) => void,
    placeholder: string
}) {

    function filterSuggestions(suggestions: Suggestion[], input: string) {
        if (!input) {
            return suggestions;
        }

        let filteredSuggestions = suggestions.filter(
            (suggestion: Suggestion) => suggestion.symbol.toLowerCase().startsWith(input)
        );
        if (filteredSuggestions.length === 0) {
            filteredSuggestions = suggestions.filter(
                (suggestion: Suggestion) => suggestion.symbol.toLowerCase().includes(input)
            );
        }
        if (filteredSuggestions.length === 0) {
            filteredSuggestions = suggestions.filter(
                (suggestion: Suggestion) => suggestion.name.toLowerCase().includes(input)
            );
        }
        return filteredSuggestions;
    }

    function colorize(text: string, subtext: string) {
        const i = text.toLowerCase().indexOf(subtext);
        return i > -1
            ?
            <span>
                {text.substr(0, i)}
                <span className="red">{text.substr(i, subtext.length)}</span>
                {text.substr(i + subtext.length)}
            </span>
            : text;
    }

    function renderSuggestion(suggestion: Suggestion, input: string) {
        return (
            <div>
                {/*<b>{colorize(suggestion.symbol, input)}</b>*/}
                {/*<br/>*/}
                <span>{colorize(suggestion.name, input)}</span>
            </div>
        );
    }

    function getSuggestionKey(suggestion: Suggestion) {
        return suggestion ? suggestion.name.toUpperCase() : '';
    }

    return (
        <Autocomplete
            suggestions={suggestions}
            callback={handleItemSelected}
            filterSuggestions={filterSuggestions}
            renderSuggestion={renderSuggestion}
            getSuggestionKey={getSuggestionKey}
            placeholder={placeholder}
        />
    )
}
