import React, {useEffect, useRef, useState} from "react";
import useSWR from "swr";
import fetchJson from "../services/fetchJson";
import {Post} from "../types/Post";
import {sorter} from "../services/sort";
import Loading from "./Loading";
import cities from "./cities";
import SearchBox from "./SearchBox";
import {Suggestion} from "../types/Suggestion";

type Token = {
    qid: string,
    token: string
}

export default function Content() {

    const swrConfig = {
        // revalidateOnFocus: false,
        // revalidateOnMount: false,
        // revalidateOnReconnect: false,
        // refreshWhenOffline: false,
        // refreshWhenHidden: false,
        refreshInterval: 0
    };
    const refTable = useRef<HTMLTableElement>(null);
    const [pageIndex, setPageIndex] = useState(1);
    const [tokensToLoad, setTokensToLoad] = useState<Token[]>([]);
    const [loadedTokens, setLoadedTokens] = useState<Token[]>([]);
    const [records, setRecords] = useState<Post[]>([]);
    const [description, setDescription] = useState('');
    const [input, setInput] = useState('');
    const [userQuery, setUserQuery] = useState<string>();
    const [userCity, setUserCity] = useState<Suggestion>();
    const [token, setToken] = useState<string>();
    const [searching, setSearching] = useState(false);
    const [queryID, setQueryID] = useState('');

    const url = userCity && userQuery ? `${process.env.REACT_APP_SERVER_ENDPOINT}/api/query?c=${userCity.symbol.toLowerCase()}&q=${userQuery}&p=${pageIndex}` : null;
    const {data: tokens, error: tokensError} = useSWR(url, fetchJson, swrConfig);

    const post_url = `${process.env.REACT_APP_SERVER_ENDPOINT}/api/post?t=${token}&qid=${queryID}`;
    const {data: post, error: postError} = useSWR(token ? post_url : null, fetchJson, swrConfig);

    useEffect(() => {
        if (tokens && tokens.length > 0) {
            tokens.forEach((token: string) => {
                const t = {qid: queryID, token: token} as Token;
                if (!loadedTokens.includes(t) && !tokensToLoad.includes(t)) {
                    setTokensToLoad(ary => [...ary, t]);
                }
            });
            setPageIndex(pageIndex + 1);
            console.log("fetched tokens: ", tokens.length, " page: ", pageIndex);
        }
    }, [loadedTokens, pageIndex, queryID, tokens, tokensToLoad]);

    useEffect(() => {
        if (tokensToLoad.length === 0) {
            setSearching(false);
        }
    }, [tokensToLoad.length]);


    useEffect(() => {
        if (tokensToLoad.length > 0) {
            console.log("tokensToLoad: ", tokensToLoad.length, "loadedTokens: ", loadedTokens.length);
            let t = tokensToLoad.pop() as Token;
            console.log("Loading token ", t);
            setToken(t.token);
        } else {
            console.log("No more");
            handleSort(null);
        }

    }, [loadedTokens, tokensToLoad]);

    useEffect(() => {
        if (post && post.token) {
            if (post.qid === queryID) {
                setRecords(ary => [...ary, post]);
                setLoadedTokens(ary => [...ary, post.token]);
            }
        }
    }, [post, queryID]);


    if (tokensError || postError) {
        return <p>Error</p>
    }

    if (!records) {
        return <Loading/>
    }


    function format(value: any) {
        if (value) {
            return value.toLocaleString()
        }
    }

    function hover(index: number) {
        setDescription(records[index].description);
    }

    function handleCitySelected(city: Suggestion) {
        setUserQuery('');
        setUserCity(city);
    }

    function onInputChange(e: any) {
        setInput(e.target.value);
    }

    function submitQuery(e: any) {
        e.preventDefault();
        setToken('');
        setPageIndex(1);
        setTokensToLoad([]);
        setLoadedTokens([]);
        setDescription('');
        setSearching(true);
        setQueryID((new Date().getTime()).toString());
        setRecords([]);
        setUserQuery(input);
    }

    function handleSort(e: any) {
        let sort = {key: 'price_per_m2', ascending: true};
        if (e) {
            const option = e.target.value.split("|");
            sort = {key: option[0], ascending: option[1] === "asc"};
        }
        setRecords(records.slice().sort(function (x, y) {
            return sorter(x, y, sort.key, sort.ascending)
        }));
    }

    return (
        <div className={"grid-container"}>
            <div className={"header"}>
                <h1><span style={{color: "red"}}>زمین‌</span>گَرد</h1>
                <h4 className={"left"}><span style={{color: "red"}}>zamin</span>gard.com</h4>
                <form onSubmit={submitQuery}>
                    <SearchBox
                        suggestions={cities}
                        handleItemSelected={handleCitySelected}
                        placeholder={"نام شهر..."}
                    />
                    <input type={"text"} onChange={onInputChange} placeholder={"نام محله..."}/>
                    <input type={"submit"} value={"بگرد"} disabled={searching || !userCity || !input}/>
                    {
                        searching && <Loading/>
                    }
                    <span style={{marginRight: "10px"}}>
                        {
                            userQuery ?
                                records.length > 0 ? `${records.length} مورد در ${userQuery} یافت شد` : "در حال جستجو..."
                                : null
                        }
                    </span>

                </form>
                {
                    records.length > 0 &&
                    <div>
                        <br/>
                        <label htmlFor="sortOptions">مرتب سازی بر اساس:</label>
                        <select name="sortOptions" id="sortOptions" onChange={handleSort}
                                defaultValue={"price_per_m2|asc"}>
                            <option value="timestamp|desc">جدیدترین</option>
                            <option value="price_per_m2|asc">قیمت متر مربع (صعودی)</option>
                            <option value="price|asc">قیمت کل (صعودی)</option>
                            <option value="surface|asc">مساحت (صعودی)</option>
                            <option value="price_per_m2|desc">قیمت متر مربع (نزولی)</option>
                            <option value="price|desc">قیمت کل (نزولی)</option>
                            <option value="surface|desc">مساحت (نزولی)</option>
                        </select>
                    </div>
                }
            </div>
            {
                description &&
                <div className={"side"}>
                    <h5>توضیحات</h5>
                    <textarea className={"description"} value={description} readOnly/>
                </div>
            }
            {
                records.length > 0 &&
                <table ref={refTable} className={"main"}>
                    <thead>
                    <tr>
                        <th>عنوان</th>
                        <th>تاریخ</th>
                        <th>مساحت</th>
                        <th>قیمت هر متر مربع</th>
                        <th>قیمت کل</th>
                        <th>آگهی دهنده</th>
                        <th>تلفن</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        records.map((post: any, index: number) =>
                            <tr tabIndex={0} key={`tr-${index}`} onMouseOver={() => hover(index)}>
                                <td>
                                    <a target={"_blank"} rel="noreferrer" href={post.url}>{post.title}</a>
                                </td>
                                <td>{post.date} </td>
                                <td>{format(post.surface)} </td>
                                <td>{post.price_per_m2 ? format(post.price_per_m2) : "توافقی"}</td>
                                <td>{post.price ? format(post.price) : "توافقی"}</td>
                                <td>{post.publisher}</td>
                                <td>{post.phone}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            }
        </div>
    )
}


