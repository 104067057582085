import React from 'react';
import './App.css';
import './styles/Theme.scss';
import './styles/Table.scss';
import './styles/Dropdown.scss';

import Content from "./components/Content";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/">
                    <Content/>
                </Route>
                {/*<Route path="/:city/:query">*/}
                {/*    <Content/>*/}
                {/*</Route>*/}
            </Switch>
        </Router>
    );
}

export default App;
