const cities = [
    {
        "symbol": "abadan",
        "name": "آبادان"
    },
    {
        "symbol": "abadeh",
        "name": "آباده"
    },
    {
        "symbol": "abdanan",
        "name": "آبدانان"
    },
    {
        "symbol": "abyek",
        "name": "آبیک"
    },
    {
        "symbol": "azarshahr",
        "name": "آذرشهر"
    },
    {
        "symbol": "azadshahr-golestan",
        "name": "آزادشهر"
    },
    {
        "symbol": "astara",
        "name": "آستارا"
    },
    {
        "symbol": "astaneh-ashrafiyeh",
        "name": "آستانه اشرفیه"
    },
    {
        "symbol": "ashkhaneh",
        "name": "آشخانه"
    },
    {
        "symbol": "aq-qala",
        "name": "آق قلا"
    },
    {
        "symbol": "amol",
        "name": "آمل"
    },
    {
        "symbol": "abhar",
        "name": "ابهر"
    },
    {
        "symbol": "arak",
        "name": "اراک"
    },
    {
        "symbol": "ardabil",
        "name": "اردبیل"
    },
    {
        "symbol": "ardakan",
        "name": "اردکان"
    },
    {
        "symbol": "urmia",
        "name": "ارومیه"
    },
    {
        "symbol": "azna",
        "name": "ازنا"
    },
    {
        "symbol": "asadabad",
        "name": "اسدآباد"
    },
    {
        "symbol": "esfarāyen",
        "name": "اسفراین"
    },
    {
        "symbol": "eslamshahr",
        "name": "اسلام‌شهر"
    },
    {
        "symbol": "eslamabad-gharb",
        "name": "اسلام‌‌آباد غرب"
    },
    {
        "symbol": "oshnavieh",
        "name": "اشنویه"
    },
    {
        "symbol": "isfahan",
        "name": "اصفهان"
    },
    {
        "symbol": "alvand",
        "name": "الوند"
    },
    {
        "symbol": "aligudarz",
        "name": "الیگودرز"
    },
    {
        "symbol": "amlash",
        "name": "املش"
    },
    {
        "symbol": "amirkala",
        "name": "امیرکلا"
    },
    {
        "symbol": "andimeshk",
        "name": "اندیمشک"
    },
    {
        "symbol": "ahar",
        "name": "اهر"
    },
    {
        "symbol": "ahvaz",
        "name": "اهواز"
    },
    {
        "symbol": "izeh",
        "name": "ایذه"
    },
    {
        "symbol": "iranshahr",
        "name": "ایرانشهر"
    },
    {
        "symbol": "izadshahr",
        "name": "ایزدشهر"
    },
    {
        "symbol": "ilam",
        "name": "ایلام"
    },
    {
        "symbol": "eyvan",
        "name": "ایوان"
    },
    {
        "symbol": "babol",
        "name": "بابل"
    },
    {
        "symbol": "babolsar",
        "name": "بابلسر"
    },
    {
        "symbol": "baneh",
        "name": "بانه"
    },
    {
        "symbol": "bojnurd",
        "name": "بجنورد"
    },
    {
        "symbol": "borazjan",
        "name": "برازجان"
    },
    {
        "symbol": "borujerd",
        "name": "بروجرد"
    },
    {
        "symbol": "boroujen",
        "name": "بروجن"
    },
    {
        "symbol": "bam",
        "name": "بم"
    },
    {
        "symbol": "bonab",
        "name": "بناب"
    },
    {
        "symbol": "bandar-abba",
        "name": "بندرعباس"
    },
    {
        "symbol": "bandar-imam-khomeini",
        "name": "بندر امام خمینی"
    },
    {
        "symbol": "bandar-anzali",
        "name": "بندر انزلی"
    },
    {
        "symbol": "bandar-torkaman",
        "name": "بندر ترکمن"
    },
    {
        "symbol": "bandar-kangan",
        "name": "بندر کنگان"
    },
    {
        "symbol": "bandar-ganaveh",
        "name": "بندر گناوه"
    },
    {
        "symbol": "bandar-mahshahr",
        "name": "بندر ماهشهر"
    },
    {
        "symbol": "bushehr",
        "name": "بوشهر"
    },
    {
        "symbol": "bukan",
        "name": "بوکان"
    },
    {
        "symbol": "behbahan",
        "name": "بهبهان"
    },
    {
        "symbol": "behshahr",
        "name": "بهشهر"
    },
    {
        "symbol": "bijar",
        "name": "بیجار"
    },
    {
        "symbol": "birjand",
        "name": "بیرجند"
    },
    {
        "symbol": "parsabad",
        "name": "پارس‌آباد"
    },
    {
        "symbol": "polsefid",
        "name": "پل سفید"
    },
    {
        "symbol": "piranshahr",
        "name": "پیرانشهر"
    },
    {
        "symbol": "pishva",
        "name": "پیشوا"
    },
    {
        "symbol": "takestan",
        "name": "تاکستان"
    },
    {
        "symbol": "talesh",
        "name": "تالش"
    },
    {
        "symbol": "tabriz",
        "name": "تبریز"
    },
    {
        "symbol": "torbat-jam",
        "name": "تربت جام"
    },
    {
        "symbol": "tonekabon",
        "name": "تنکابن"
    },
    {
        "symbol": "tuyserkan",
        "name": "تویسرکان"
    },
    {
        "symbol": "tehran",
        "name": "تهران"
    },
    {
        "symbol": "javanrud",
        "name": "جوانرود"
    },
    {
        "symbol": "juybar",
        "name": "جویبار"
    },
    {
        "symbol": "jahrom",
        "name": "جهرم"
    },
    {
        "symbol": "jiroft",
        "name": "جیرفت"
    },
    {
        "symbol": "chaboksar",
        "name": "چابکسر"
    },
    {
        "symbol": "chabahar",
        "name": "چابهار"
    },
    {
        "symbol": "chaf-chamkhale",
        "name": "چاف و چمخاله"
    },
    {
        "symbol": "chalu",
        "name": "چالوس"
    },
    {
        "symbol": "chamestan",
        "name": "چمستان"
    },
    {
        "symbol": "chahar-dangeh",
        "name": "چهاردانگه"
    },
    {
        "symbol": "hamidia",
        "name": "حمیدیا"
    },
    {
        "symbol": "khorramdarreh",
        "name": "خرمدره"
    },
    {
        "symbol": "khorramshahr",
        "name": "خرمشهر"
    },
    {
        "symbol": "khorramabad",
        "name": "خرم‌آباد"
    },
    {
        "symbol": "khoshkbijar",
        "name": "خشکبیجار"
    },
    {
        "symbol": "khalkhal",
        "name": "خلخال"
    },
    {
        "symbol": "khomein",
        "name": "خمین"
    },
    {
        "symbol": "khoy",
        "name": "خوی"
    },
    {
        "symbol": "darab",
        "name": "داراب"
    },
    {
        "symbol": "damghan",
        "name": "دامغان"
    },
    {
        "symbol": "dezful",
        "name": "دزفول"
    },
    {
        "symbol": "damavand",
        "name": "دماوند"
    },
    {
        "symbol": "dorud",
        "name": "دورود"
    },
    {
        "symbol": "dogonbadan",
        "name": "دوگنبدان"
    },
    {
        "symbol": "dehdasht",
        "name": "دهدشت"
    },
    {
        "symbol": "dehloran",
        "name": "دهلران"
    },
    {
        "symbol": "ramsar",
        "name": "رامسر"
    },
    {
        "symbol": "ramhormoz",
        "name": "رامهرمز"
    },
    {
        "symbol": "rasht",
        "name": "رشت"
    },
    {
        "symbol": "rezvanshahr",
        "name": "رضوانشهر"
    },
    {
        "symbol": "rafsanjan",
        "name": "رفسنجان"
    },
    {
        "symbol": "rudsar",
        "name": "رودسر"
    },
    {
        "symbol": "royan",
        "name": "رویان"
    },
    {
        "symbol": "zabol",
        "name": "زابل"
    },
    {
        "symbol": "zahedan",
        "name": "زاهدان"
    },
    {
        "symbol": "zarand",
        "name": "زرند"
    },
    {
        "symbol": "zanjan",
        "name": "زنجان"
    },
    {
        "symbol": "zibakenar",
        "name": "زیباکنار"
    },
    {
        "symbol": "ari",
        "name": "ساری"
    },
    {
        "symbol": "aveh",
        "name": "ساوه"
    },
    {
        "symbol": "abzevar",
        "name": "سبزوار"
    },
    {
        "symbol": "arab",
        "name": "سراب"
    },
    {
        "symbol": "aravan",
        "name": "سراوان-سیستان و بلوچستان"
    },
    {
        "symbol": "arpol-zahab",
        "name": "سرپل ذهاب"
    },
    {
        "symbol": "orkhrood",
        "name": "سرخرود"
    },
    {
        "symbol": "ardasht",
        "name": "سردشت"
    },
    {
        "symbol": "aqqez",
        "name": "سقز"
    },
    {
        "symbol": "alma",
        "name": "سلماس"
    },
    {
        "symbol": "alman-shahr",
        "name": "سلمان‌شهر"
    },
    {
        "symbol": "emnan",
        "name": "سمنان"
    },
    {
        "symbol": "onqor",
        "name": "سنقر"
    },
    {
        "symbol": "anandaj",
        "name": "سنندج"
    },
    {
        "symbol": "usangerd",
        "name": "سوسنگرد"
    },
    {
        "symbol": "ahand",
        "name": "سهند"
    },
    {
        "symbol": "iahkal",
        "name": "سیاهکل"
    },
    {
        "symbol": "irjan",
        "name": "سیرجان"
    },
    {
        "symbol": "hahroud",
        "name": "شاهرود"
    },
    {
        "symbol": "hahin-dej",
        "name": "شاهین دژ"
    },
    {
        "symbol": "hush",
        "name": "شوش"
    },
    {
        "symbol": "hooshtar",
        "name": "شوشتر"
    },
    {
        "symbol": "hahrekord",
        "name": "شهرکرد"
    },
    {
        "symbol": "hiraz",
        "name": "شیراز"
    },
    {
        "symbol": "hirvan",
        "name": "شیروان"
    },
    {
        "symbol": "adra",
        "name": "صدرا-فارس"
    },
    {
        "symbol": "omeh-sara",
        "name": "صومعه‌سرا"
    },
    {
        "symbol": "taleqan",
        "name": "طالقان"
    },
    {
        "symbol": "taba",
        "name": "طبس"
    },
    {
        "symbol": "abbasabad-mazandaran",
        "name": "عباس‌آباد"
    },
    {
        "symbol": "aliabad-katul",
        "name": "علی‌آباد کتول"
    },
    {
        "symbol": "farrokhshahr",
        "name": "فرخ‌شهر"
    },
    {
        "symbol": "ferdow",
        "name": "فردوس"
    },
    {
        "symbol": "fereydunkenar",
        "name": "فریدون‌کنار"
    },
    {
        "symbol": "falavarjan",
        "name": "فلاورجان"
    },
    {
        "symbol": "fuman",
        "name": "فومن"
    },
    {
        "symbol": "qaemshahr",
        "name": "قائم‌شهر"
    },
    {
        "symbol": "ghayen",
        "name": "قاين"
    },
    {
        "symbol": "qorveh",
        "name": "قروه"
    },
    {
        "symbol": "qazvin",
        "name": "قزوین"
    },
    {
        "symbol": "qeshm",
        "name": "قشم"
    },
    {
        "symbol": "qom",
        "name": "قم"
    },
    {
        "symbol": "qeydar",
        "name": "قیدار"
    },
    {
        "symbol": "kashan",
        "name": "کاشان"
    },
    {
        "symbol": "karaj",
        "name": "کرج"
    },
    {
        "symbol": "kordkuy",
        "name": "کردکوی"
    },
    {
        "symbol": "kerman",
        "name": "کرمان"
    },
    {
        "symbol": "kermanshah",
        "name": "کرمانشاه"
    },
    {
        "symbol": "kelachay",
        "name": "کلاچای"
    },
    {
        "symbol": "kelarabad",
        "name": "کلارآباد"
    },
    {
        "symbol": "kelarestan",
        "name": "کلاردشت"
    },
    {
        "symbol": "kalale",
        "name": "کلاله"
    },
    {
        "symbol": "kangavar",
        "name": "کنگاور"
    },
    {
        "symbol": "kuhdasht",
        "name": "کوهدشت"
    },
    {
        "symbol": "kiashahr",
        "name": "کیاشهر"
    },
    {
        "symbol": "kish",
        "name": "کیش"
    },
    {
        "symbol": "gorgan",
        "name": "گرگان"
    },
    {
        "symbol": "garmsar",
        "name": "گرمسار"
    },
    {
        "symbol": "golpayegan",
        "name": "گلپایگان"
    },
    {
        "symbol": "gomishan",
        "name": "گمیشان"
    },
    {
        "symbol": "gonabad",
        "name": "گناباد"
    },
    {
        "symbol": "gonbad-kavu",
        "name": "گنبد کاووس"
    },
    {
        "symbol": "lahijan",
        "name": "لاهیجان"
    },
    {
        "symbol": "lordegan",
        "name": "لردگان"
    },
    {
        "symbol": "lashtenesha",
        "name": "لشت نشا"
    },
    {
        "symbol": "langarud",
        "name": "لنگرود"
    },
    {
        "symbol": "masal",
        "name": "ماسال"
    },
    {
        "symbol": "maku",
        "name": "ماکو"
    },
    {
        "symbol": "mahalat",
        "name": "محلات"
    },
    {
        "symbol": "mohammadiyeh",
        "name": "محمدیه-قزوین"
    },
    {
        "symbol": "mahmudabad",
        "name": "محمودآباد"
    },
    {
        "symbol": "maragheh",
        "name": "مراغه"
    },
    {
        "symbol": "marand",
        "name": "مرند"
    },
    {
        "symbol": "marivan",
        "name": "مریوان"
    },
    {
        "symbol": "masjed-soleyman",
        "name": "مسجد سلیمان"
    },
    {
        "symbol": "meshgin-shahr",
        "name": "مشکین‌شهر"
    },
    {
        "symbol": "mashhad",
        "name": "مشهد"
    },
    {
        "symbol": "malayer",
        "name": "ملایر"
    },
    {
        "symbol": "mahabad",
        "name": "مهاباد"
    },
    {
        "symbol": "miandoab",
        "name": "میاندوآب"
    },
    {
        "symbol": "mianeh",
        "name": "میانه"
    },
    {
        "symbol": "meybod",
        "name": "میبد"
    },
    {
        "symbol": "minab",
        "name": "میناب"
    },
    {
        "symbol": "minoodasht",
        "name": "مینودشت"
    },
    {
        "symbol": "najafabad",
        "name": "نجف‌آباد"
    },
    {
        "symbol": "nasimshahr",
        "name": "نسیم‌شهر"
    },
    {
        "symbol": "nazarabad",
        "name": "نظرآباد"
    },
    {
        "symbol": "naqadeh",
        "name": "نقده"
    },
    {
        "symbol": "neka",
        "name": "نکا"
    },
    {
        "symbol": "nur",
        "name": "نور"
    },
    {
        "symbol": "nurabad",
        "name": "نورآباد"
    },
    {
        "symbol": "nowshahr",
        "name": "نوشهر"
    },
    {
        "symbol": "nahavand",
        "name": "نهاوند"
    },
    {
        "symbol": "neyshabur",
        "name": "نیشابور"
    },
    {
        "symbol": "hamedan",
        "name": "همدان"
    },
    {
        "symbol": "yasuj",
        "name": "یاسوج"
    },
    {
        "symbol": "yazd",
        "name": "یزد"
    }
];
export default cities;
