import React, {useCallback, useEffect, useState} from "react";
import {Suggestion} from "../types/Suggestion";

export default function AutoComplete(
    {suggestions, callback, filterSuggestions, getSuggestionKey, renderSuggestion, placeholder}: {
    suggestions: Suggestion[]
    callback: (suggestion: Suggestion) => void
    filterSuggestions: (suggestions: Suggestion[], input: string) => Suggestion[]
    getSuggestionKey: (suggestion: Suggestion) => string
    renderSuggestion: (suggestion: Suggestion, input: string) => React.ReactNode,
    placeholder: string
}) {

    // The active selection's index
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    // The suggestions that match the user's input
    const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestion[]>([]);
    // Whether or not the suggestion list is shown
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [userInput, setUserInput] = useState('');

    const onSelected = useCallback(()=> {
        // Update the user input and reset the rest of the state
        const selectedSuggestion = filteredSuggestions[activeSuggestion];
        setUserInput(getSuggestionKey(selectedSuggestion));
        callback(selectedSuggestion);
        setActiveSuggestion(0);
        setFilteredSuggestions([]);
        setShowSuggestions(false);
    }, [activeSuggestion, callback, filteredSuggestions, getSuggestionKey]);

    useEffect(()=>{
        if (filteredSuggestions.length === 1 && getSuggestionKey(filteredSuggestions[0]) === userInput){
            onSelected();
        }
    }, [filteredSuggestions, filteredSuggestions.length, getSuggestionKey, onSelected, userInput])

    // Event fired when the input value is changed
    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const input = e.target.value.toLowerCase();
        setUserInput(input);
        if (input === '') {
            setShowSuggestions(false);
            return;
        }
        // Filter our suggestions that don't contain the user's input
        setFilteredSuggestions(filterSuggestions(suggestions, input));
        // Update the user input and filtered suggestions, reset the active
        // suggestion and make sure the suggestions are shown
        setActiveSuggestion(0);
        setShowSuggestions(true);
    }


    function onEscaped() {
        setShowSuggestions(false);
        setUserInput('');
        setActiveSuggestion(0);
        setFilteredSuggestions([]);
    }

    // Event fired when the user presses a key down
    function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        switch (e.key) {
            case 'Enter':
                onSelected();
                break;
            case 'Escape':
                onEscaped();
                break;
            case 'ArrowUp':
                if (activeSuggestion > 0) {
                    setActiveSuggestion(activeSuggestion => activeSuggestion - 1);
                }
                break;
            case 'ArrowDown':
                if (activeSuggestion < filteredSuggestions.length - 1) {
                    setActiveSuggestion(activeSuggestion => activeSuggestion + 1);
                }
        }
    }


    function onHover(e: React.MouseEvent<HTMLLIElement>, index: number) {
        setActiveSuggestion(activeSuggestion => index);
    }

    return (
        <div className="dropdown">
            <input
                type="text"
                placeholder={placeholder}
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={userInput}
            />
            {showSuggestions && userInput && filteredSuggestions.length > 0 &&
            <ul className="dropdown-content">
                {filteredSuggestions.map((suggestion, index) => {
                    return (
                        <li
                            className={index === activeSuggestion ? "dropdown-active" : ""}
                            key={index}
                            onClick={onSelected}
                            onMouseOver={(e) => onHover(e, index)}
                            tabIndex={0}

                        >
                            {renderSuggestion(suggestion, userInput)}
                        </li>
                    );
                })}
            </ul>
            }
        </div>
    );

}
